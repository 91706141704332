<template>
    <Pop ref="pop" bg="#fff" position="Right">
        <template v-slot:popContent>
            <div :style="pageStyle" class="cityContent">
                <div class="searchHeight">
                    <div class="shopSearchRow">
                        <span>
                            <Svgs color="#C0C4CD" classes="shopSearchSvg" name="iconsousuo"></Svgs>
                            <input type="text" placeholder="请输入城市名称" v-model="cityFilter" @input="filterCity">
                        </span>
                        <span class="searchCancel" @click="openPop">取消</span>
                    </div>

                    <div class="searchHis">
                        <p class="searchHeadTxt">当前定位 </p>
                        <ul class="searchVague">
                            <li @click="searchChoose(nowCity)">
                                <Svgs color="#007AFF" classes="vagueSvg" name="icondaohang-tianchong"></Svgs>
                                <span v-if="nowCity[cityTxt]">{{nowCity[cityTxt]}}</span>
                                <span v-if="!nowCity[cityTxt]">{{positionStatus}}</span>
                                <span class="rePos" @click="rePos($event)">重新定位</span>
                            </li>
                        </ul>
                    </div>

                    <div class="searchHis">
                        <p class="searchHeadTxt">热门城市</p>
                        <ul class="searchHisList">
                            <li v-for="(his,indexs) in cityHis" :class="{'searchHisOn':his.pos}" :key="indexs"
                                @click="chooseOk(his)">
                                {{his[cityTxt]}}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="cityList" id="#">
                    <div class="searchHis" :id="pro.key" v-for="(pro,index) in searchCityList" :key="index">
                        <div v-if="pro.filter">
                            <p class="searchHeadTxt">{{pro.key}}</p>
                            <div class="searchVague searchVague2">
                                <ul v-for="(city,ind) in pro.arr" :key="ind" @click="searchChoose(city)">
                                    <li v-if="!city.filter">
                                        {{city[cityTxt]}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <ul class="cityPoint">
                    <li v-for="(n,index) in point" :key="index"><a :href="'#'+n">{{n}}</a></li>
                </ul>
            </div>
        </template>
    </Pop>
</template>

<script>
    import remoteLoad from './remoteLoad'
    import { getCookie } from "tiny-cookie";
import {
	SmartStorage
} from 'smart-core-util'
    export default {
        name: "ShopSearch",
        props: [
            'cityList', //城市数据源
            'txt',//城市中文
            'val',//城市val
            'keys'//排序依据如"CD"=成都
        ],
        watch: {
            'cityList': function () {
                this.setCityList();
                this.setNowCity(this.nowCitys);
            }
        },
        data() {
            return {
                keyword: '',
                pageStyle: {},
                point: ['#'],
                searchCityList: [],
                cityHis: [],
                nowCity: {},
                cityTxt: '',
                cityVal: '',
                cityKey: '',
                cityFilter: '',
                chooseCitys: {},
                nowCitys: {},
                openFlag: false,
                positionStatus: '',
            }
        },
        created() {
            this.cityTxt = this.txt || 'txt';
            this.cityVal = this.val || 'txt';
            this.citykey = this.keys || 'pyVal';
            this.positionStatus = '定位中，请稍候...';
            this.setStyle();
            this.setAZ();
            this.getHis();
        },
        methods: {
            //按26字母循环分类城市数据
            setCityList() {
                this.searchCityList = [];
                for (let i = 0; i < 26; i++) {
                    this.searchCityList.push({
                        key: String.fromCharCode(65 + i),
                        filter: true,
                        arr: []
                    })
                }
                this.cityList.map(value => {
                    let num = value[this.citykey].split('')[0].charCodeAt() - 65;
                    this.searchCityList[num].arr.push(value);
                }, this)
            },
            filterCity() {
                for (let q = 0; q < this.searchCityList.length; q++) {
                    let arr = this.searchCityList[q].arr;
                    let flag = false;
                    for (let j = 0; j < arr.length; j++) {
                        if (arr[j][this.cityVal].indexOf(this.cityFilter) != -1) {
                            arr[j].filter = false;
                            flag = true;
                        } else {
                            arr[j].filter = true;
                        }
                    }
                    this.searchCityList[q].filter = flag;
                }
            },
            //获取已选择过的城市
            async getHis() {
                await this.nativeGet(this.getHisCity);
                // console.log(cityHis)
            },
            getHisCity(city) {
                this.cityHis = city;
            },
            backList() {
                this.$router.back();
            },
            //地图初始化
            async rePos(e) {
                if (e) {
                    e.stopPropagation();
                }
                if (window.AMap && window.AMapUI) {
                    this.initMap();
                    // 未载入高德地图API，则先载入API再初始化
                } else {
                    await remoteLoad(
                        "https://webapi.amap.com/maps?v=1.4.6&key=2bb900ee621fcd705cf4ca3d526754f5"
                    );
                    await remoteLoad("https://webapi.amap.com/ui/1.0/main.js?v=1.0.11");
                    this.initMap();
                }

            },
            //地图定位
            initMap() {
                this.positionStatus = '定位中，请稍候...';
                let AMap = window.AMap;
                var map = new AMap.Map('container', {
                    resizeEnable: true
                });
                let _this = this;
                map.plugin('AMap.CitySearch', function () {
                    var citySearch = new AMap.CitySearch()
                    citySearch.getLocalCity(function (status, result) {
                        if (status === 'complete' && result.info === 'OK') {
                            _this.positionStatus = '';
                            _this.setNowCity(result.city);
                        } else {
                            this.$store.commit('setValueAlert', '定位失败！请手动选择！');
                        }
                    })
                })
            },
            //标注当前城市
            async setNowCity(city) {
                this.nowCitys = city;
                for (let q = 0; q < this.cityList.length; q++) {
                    if (city == this.cityList[q][this.cityTxt]) {
                        this.$set(this.cityList[q], 'pos', true);
                        this.nowCity = this.cityList[q];
                    } else {
                        this.$set(this.cityList[q], 'pos', false);
                    }
                }
                await this.nativeGet(this.setCityHost)
            },
            setCityHost(localCity) {
                let city = this.nowCitys;
                for (let i = 0; i < localCity.length; i++) {
                    if (localCity[i][this.cityVal] == city) {
                        this.$set(localCity[i], 'pos', true);
                    } else {
                        this.$set(localCity[i], 'pos', false);
                    }
                }
                this.cityHis = localCity;
                if (typeof localCity != 'object') {
                    this.nativeSave(localCity);
                }
            },
            openPop() {
                if (!this.openFlag) {
                    this.rePos();
                    this.openFlag = true;
                }
                this.$refs.pop.changePop();
            },
            nativeSave: async function (params) {
                // let isH5 = SmartStorage.get('isH5');
                // if (isH5) {
                    localStorage.setItem("searchCity", JSON.stringify(params));
                // } else {
                //     this.iJsBridge.call({
                //         method: "SMDataStorage.setItem",
                //         postData: {["searchCity"]: JSON.stringify(params)}
                //     });
                // }
            },
            nativeGet: async function (callBack) {
                let isH5 = SmartStorage.get('isH5');
                let data = [];
                // if (isH5) {
                    data = JSON.parse(localStorage.getItem("searchCity")) || [];
                    callBack(data);
                // } else {
                //     this.iJsBridge.call({
                //         method: "SMDataStorage.getItem",
                //         postData: {
                //             key: ["searchCity"]
                //         },
                //         callback: function (datas) {
                //             // console.log(JSON.parse(datas.searchCity || ''), 222)
                //             // return JSON.parse(datas.searchCity || '');
                //             callBack(JSON.parse(datas.searchCity ? datas.searchCity : '[]'))
                //         }
                //     });
                // }
            },
            //点击列表保存已选择过的城市
            async searchChoose(city) {
                this.chooseCitys = city;
                await this.nativeGet(this.setChoose);
                // console.log(localCity)
            },
            setChoose(localCity) {
                let city = this.chooseCitys;
                let flag = true;
                for (let i = 0; i < localCity.length; i++) {
                    if (localCity[i][this.cityVal] == city[this.cityVal]) {
                        flag = false;
                    }
                }
                if (flag) {
                    if (localCity.length == 5) {
                        localCity.pop();
                    }
                    localCity.unshift(city);
                    this.nativeSave(localCity);
                    this.getHis();
                }
                this.chooseOk(city);
            },
            //选择完毕
            chooseOk(city) {
                this.$emit('onOk', city);
                this.openPop();
            },
            //循环右侧定位（右边26字母）
            setAZ() {
                for (let i = 0; i < 26; i++) {
                    this.point.push(String.fromCharCode(65 + i))
                }
            },
            //设置弹框高度
            setStyle() {
                let height = window.innerHeight + 'px';
                this.pageStyle = {
                    height: height,
                    background: '#fff'
                }
            },
        }
    }
</script>

<style lang="scss">
    @import "SearchCity";
</style>